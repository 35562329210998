import analytics from "@mobi/libraries/analytics"

// Components
import { Accordion, Button, Text } from "@mobi/ds"

//Hooks
import useMake from "hooks/useMake"

// Styles
import "./index.scss"

// Utils
import { goToForm } from "utils/forms"

//Data
import { accordionData } from "./dataContent"

const Faq = () => {
  const { onElementClicked } = useMake()

  const prepareToMakeTrack = () => {
    const webElement = {
      elementType: "button",
      location: "faq section",
      name: "startSimulationFaq",
      text: "iniciar simulação",
    }

    onElementClicked(webElement)
  }

  const onClick = () => {
    goToForm()
    prepareToMakeTrack()
    analytics.track("buttonClickGa", {
      eventName: "IniciarSimulacaoDuvidas",
    })
  }

  return (
    <section className="faq">
      <div className="faq__container">
        <Text as="h2" size="xxl" data-aos="fade-up">
          <strong>
            tire todas suas dúvidas sobre financiamento de veículos
          </strong>
        </Text>
        <div className="faq__wrapper" data-aos="fade-up" data-aos-delay={100}>
          <Accordion items={accordionData} />
        </div>
        <Button className="faq__button" onClick={onClick} fluid>
          iniciar simulação
        </Button>
      </div>
    </section>
  )
}

export default Faq
