// Components
import { Link } from "@mobi/ds"
import { Icon } from "@vehicles/components"
import { Text } from "@mobi/ds"
import Identity from "../Identity"

// Styles
import "./index.scss"

const Hero = () => {
  return (
    <>
      <section className="hero">
        <div className="hero__container">
          <div className="hero__holder">
            <div className="hero__text-wrapper">
              <Text as="h1" size="xxl">
                <strong>Financiamento para seu CARRO.</strong>
              </Text>
              <Text size="lg">
                <strong>Feito para caber no seu bolso ;)</strong>
              </Text>
            </div>
          </div>
          <Identity />
        </div>
      </section>
    </>
  )
}

export default Hero
