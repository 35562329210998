export const steps = [
  {
    title: "simule quanto quer pagar",
    description: "Parcelas personalizadas para você",
    number: 1,
  },
  {
    title: "analisamos a sua sugestão",
    description: "E, logo em seguida, você sabe se tem crédito aprovado",
    number: 2,
  },
  {
    title: "é hora de fechar negócio!",
    description: "Fale com um de nossos especialistas",
    number: 3,
  },
]
