import { useEffect } from "react"

import { HOMEPATH } from "gatsby-env-variables"
import analytics from "@mobi/libraries/analytics"

// Components
import { Footer, Link, CookieBar } from "@mobi/ds"
import Header from "components/Header"
import Layout from "components/Layout"
import Faq from "./components/Faq"
import Steps from "./components/Steps"
import Conditions from "./components/Conditions"
import Benefits from "./components/Benefits"
import Hero from "./components/Hero"

// Styles
import "./index.scss"

const Home = () => {
  useEffect(() => {
    analytics.track("pageLoad", { pageName: "LandingPage" })
  }, [])

  return (
    <Layout className="-home">
      <div className="accessibility-menu">
        <Link
          label={"Começar simulação"}
          href={"#hero"}
          className="accessibility-menu__item"
        />
        <Link
          label={"Passos da simulação"}
          href={"#steps"}
          className="accessibility-menu__item"
        />
        <Link
          label={"Condições"}
          href={"#conditions"}
          className="accessibility-menu__item"
        />
        <Link
          label={"Benefícios"}
          href={"#benefits"}
          className="accessibility-menu__item"
        />
        <Link
          label={"Dúvidas frequentes"}
          href={"#faq"}
          className="accessibility-menu__item"
        />
        <Link
          label={"Ir para o rodapé"}
          href={"#footer"}
          className="accessibility-menu__item"
        />
      </div>
      <Header
        prevUrl={HOMEPATH}
        dynamicLogo={"itau"}
        linkLogoUrl={HOMEPATH}
        isActiveButtonAccess={false}
        mobileInfosApp={false}
        isHome={true}
      />
      <Hero />
      <Steps />
      <Conditions />
      <Benefits />
      <Faq />
      <Footer
        links={{
          urlHelpPage: "https://www.itau.com.br/atendimento-itau/para-voce",
        }}
      />
      <CookieBar />
    </Layout>
  )
}

export default Home
