export const accordionData = [
  {
    title: "Quais veículos posso financiar pelo Itaú?",
    children:
      "Financiamos veículos leves: carros, vans e alguns utilitários, como os da linha Hyundai, Agrale, Iveco, Kia e Renault. Os veículos também devem ter, no máximo 20 anos de idade a partir do Ano Modelo.",
  },
  {
    title: `O que é "ano modelo"?`,
    children:
      "Todo veículo possui o ano de fabricação e o ano modelo, geralmente apresentados no formato fabricação/modelo (2018/2019, por exemplo). Para o nosso financiamento você precisa olhar o Ano Modelo.",
  },
  {
    title: "Posso financiar uma moto?",
    children:
      "Para financiamento de motos, você pode procurar revendas parceiras do Itaú para essa finalidade.",
  },
  {
    title:
      "Quais são os documentos necessários para fazer um financiamento de veículos com o Itaú?",
    children:
      "Para fazer um financiamento no Itaú, é necessário que o responsável pelo contrato tenha: Em casos de usados - CNH, o documento do veículo e os dados do vendedor. Em casos de Zero KM - CNH, nota fiscal do veículo e os dados da loja.",
  },
]
