import { useEffect } from "react"
import analytics from "@mobi/libraries/analytics"

// Components
import { Icon } from "@vehicles/components"
import { Button, Text } from "@mobi/ds"
import Img from "components/Img"

//Hooks
import useMake from "hooks/useMake"

// Styles
import "./index.scss"

// Utils
import { goToForm } from "utils/forms"

//Data
import { steps } from "./dataContent"

const Conditions = () => {
  const { onElementClicked } = useMake()

  const prepareToMakeTrack = () => {
    const webElement = {
      elementType: "button",
      location: "conditions section",
      name: "startSimulationConditions",
      text: "iniciar simulação",
    }

    onElementClicked(webElement)
  }

  useEffect(() => {
    const element = document.querySelector(".conditions .conditions__image")

    if (element) {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          const isVisible = entry.intersectionRatio > 0
          if (isVisible) {
            element.classList.add("conditions__image--loaded")
            observer.unobserve(element)
          }
        })
      })
      observer.observe(element)
    }
  }, [])

  return (
    <section className="conditions">
      <div className="conditions__container">
        <div className="conditions__holder">
          <Text as="h2" data-aos="fade-up" size="xxl">
            <strong>as melhores condições para comprar seu veículo</strong>
          </Text>
          <div className="conditions__list">
            {steps.map(({ icon, title, description }, index) => {
              return (
                <div
                  className="conditions__list__item"
                  key={title}
                  data-aos="fade-up"
                  data-aos-delay={index * 101}
                >
                  <div className="conditions__list__holder">
                    <Icon id={icon} />
                  </div>
                  <div className="conditions__list__holder">
                    <Text as="h3" size="sm">
                      <strong>{title}</strong>
                    </Text>
                    <Text size="md">{description}</Text>
                  </div>
                </div>
              )
            })}
          </div>
          <Button
            fluid
            onClick={() => {
              goToForm()
              prepareToMakeTrack()
              analytics.track("buttonClickGa", {
                eventName: "IniciarSimulacaoCondicoes",
              })
            }}
            data-aos="fade-up"
            data-aos-delay="100"
          >
            iniciar simulação
          </Button>
        </div>
        <div className="conditions__holder">
          <Img
            className="conditions__image"
            file="conditions-image"
            alt=""
            role="presentation"
          />
        </div>
      </div>
    </section>
  )
}

export default Conditions
