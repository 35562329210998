import { useForm } from "react-hook-form"

// Components
import If from "@mobi/components/If"
import Loader from "components/Loader"
import { Input, Text } from "@mobi/ds"
import Sender from "components/Sender"

// Hooks
import useIdentity from "./useIdentity"
import useMake from "hooks/useMake"

//Styles
import "./index.scss"

const Identity = () => {
  const { onSubmit, isLoading } = useIdentity()
  const { onFormContinued } = useMake()

  const formProps = useForm({
    mode: "onChange",
  })

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = formProps

  const prepareToMakeTrack = () => {
    const formValues = formProps.getValues()
    const fieldsInfo = Object.entries(formValues).map((entry) => entry)
    const stepContext = {
      stepName: "CPF",
      stepNumber: 1,
    }
    onFormContinued(fieldsInfo, stepContext)
  }

  const send = (values) => {
    onSubmit(values)
    prepareToMakeTrack()
  }

  return (
    <div className="hero-identity">
      <If condition={isLoading} renderIf={<Loader />} />
      <div className="hero-identity__box">
        <Text as="h2" size="md">
          <strong>simule agora e encontre a melhor oferta para você</strong>
        </Text>
        <div className="hero-identity__form-wrapper">
          <form onSubmit={handleSubmit(send)}>
            <Input.Cpf
              label="cpf"
              name="documentNumber"
              autoComplete="on"
              control={control}
            />
            <Sender
              isNextDisabled={!isValid}
              hasPrev={false}
              nextLabel="simular"
            />
          </form>
        </div>
      </div>
    </div>
  )
}

export default Identity
