import { useState, useEffect, useContext } from "react"
import { navigate } from "gatsby"

// Services
import { clearToken } from "services/auth"

// Utils
import {
  removeFromSessionStorage,
  saveOnSessionStorage,
  removeFromSessionStorageWithoutPrefix,
} from "utils/storage"
import { getUrlParamAll } from "utils/browser"
import getQueryStringFromObject from "utils/getQueryStringFromObject"
import analytics from "@mobi/libraries/analytics"

// Contexts
import { Context } from "contexts/simulation"

const useIdentity = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { simulation, setSimulation } = useContext(Context)

  useEffect(() => {
    clearToken()
    ;["phone", "document", "simulation"].forEach((item) =>
      removeFromSessionStorage({ name: item })
    )
    removeFromSessionStorageWithoutPrefix({ name: "has-send" })
  }, [])

  return {
    isLoading,
    onSubmit: (payload) => {
      setIsLoading(true)
      saveOnSessionStorage({
        name: "document",
        value: payload.documentNumber,
      })

      analytics.track("buttonClickGa", { eventName: "IniciarSimulacaoDados" })

      const query = getQueryStringFromObject(getUrlParamAll())

      setSimulation({
        ...simulation,
        currentFormStep: "personal-data",
      })

      navigate(`formulario/${query}`)
    },
  }
}

export default useIdentity
