export const carouselItems = [
  {
    file: "portoseguro-logo",
    fileAlt: "Logo Porto Seguro",
    text: "Até 10% de desconto para proteger o seu carro",
    variant: "porto",
  },
  {
    file: "icarros-logo",
    fileAlt: "Logo iCarros",
    text: "100% de desconto para anunciar no iCarros caso seja correntista",
    variant: "carros",
  },
]
