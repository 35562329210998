export const steps = [
  {
    title: "Fácil, simples e seguro!",
    description:
      "Simulação e análise de crédito instantânea, com toda a segurança e experiência do Itaú",
    icon: "icon-lock",
  },
  {
    title: "Taxa exclusiva para financiar seu carro",
    description:
      "O melhor crédito do mercado para comprar seu carro, com prazo de até 60 meses",
    icon: "icon-promo",
  },
  {
    title: "Especialistas em financiamento",
    description:
      "Temos especialistas prontos para tirar as suas dúvidas durante todo o processo",
    icon: "icon-user",
  },
  {
    title: "Economize e benefícios",
    description: "Ao financiar com Itaú, você tem benefícios exclusivos",
    icon: "icon-money",
  },
]
