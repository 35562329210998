import { HOMEPATH } from "gatsby-env-variables"

//Components
import { Link, Text } from "@mobi/ds"
import CarouselSlider from "components/CarouselSlider"

//Hooks
import useMake from "hooks/useMake"

// Styles
import "./index.scss"

const Benefits = () => {
  const { onElementClicked } = useMake()

  const prepareToMakeTrack = () => {
    const webElement = {
      elementType: "button",
      location: "benefits section",
      name: "benefitsPersonnalite",
      text: "saiba mais sobre cada benefício",
    }

    onElementClicked(webElement)
  }

  return (
    <section className="benefits">
      <div className="benefits__container">
        <Text as="h2" size="xxl" weight={800}>
          aproveite os benefícios
        </Text>

        <CarouselSlider />

        <div className="benefits__link">
          <Link
            href={`${HOMEPATH}beneficios`}
            label="saiba mais sobre cada benefício"
            onClick={() => {
              prepareToMakeTrack()
            }}
            target="_blank"
          />
        </div>
      </div>
    </section>
  )
}

export default Benefits
