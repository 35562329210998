//Components
import { Carousel, CarouselItem, Text } from "@mobi/ds"
import Img from "components/Img"
import If from "@mobi/components/If"

// Utils
import isMobile from "@mobi/utils/browser/isMobile"

//Data
import { carouselItems } from "./dataContent"

// Styles
import "./index.scss"

const CarouselSlider = () => {
  return (
    <>
      <If
        condition={isMobile}
        renderIf={
          <>
            <div className="carousel">
              <Carousel
                bullets={true}
                arrows={true}
                variant="slider"
                options={{
                  gap: 16,
                  keyboard: false,
                  perView: 1,
                }}
              >
                {carouselItems.map(({ file, fileAlt, text, variant }) => (
                  <CarouselItem key={fileAlt}>
                    <div className="carousel__item">
                      <div
                        className={`carousel__header carousel__header--${variant}`}
                      />
                      <div className="carousel__body">
                        <Img
                          className="carousel__logo"
                          file={file}
                          alt={fileAlt}
                        />
                        <Text as="p" size="md" weight="400">
                          {text}
                        </Text>
                      </div>
                    </div>
                  </CarouselItem>
                ))}
              </Carousel>
            </div>
          </>
        }
        renderElse={
          <ul className="carousel">
            {carouselItems.map(({ file, fileAlt, text, variant }) => (
              <li className="carousel__item" key={variant}>
                <div
                  className={`carousel__header carousel__header--${variant}`}
                />
                <div className="carousel__body">
                  <Img className="carousel__logo" file={file} alt={fileAlt} />
                  <Text as="p" size="md" weight="400">
                    {text}
                  </Text>
                </div>
              </li>
            ))}
          </ul>
        }
      />
    </>
  )
}

export default CarouselSlider
