import analytics from "@mobi/libraries/analytics"

// Components
import { Text, Button } from "@mobi/ds"

// Styles
import "./index.scss"

//Hooks
import useMake from "hooks/useMake"

// Assets
import StepsSvg from "images/steps-image.svg"

// Utils
import { goToForm } from "utils/forms"

//Data
import { steps } from "./dataContent"

const Steps = () => {
  const { onElementClicked } = useMake()

  const prepareToMakeTrack = () => {
    const webElement = {
      elementType: "button",
      location: "steps section",
      name: "startSimulationSteps",
      text: "iniciar simulação",
    }

    onElementClicked(webElement)
  }

  return (
    <section className="steps">
      <div className="steps__container">
        <div className="steps__holder">
          <div className="steps__title">
            <Text as="h2" size="xxl" data-aos="fade-up">
              <strong>seu veículo em apenas alguns cliques de distância</strong>
            </Text>
          </div>
          <div className="steps__image" data-aos="fade-up" data-aos-delay="100">
            <StepsSvg className="image" role="presentation" />
          </div>
        </div>
        <div className="steps__holder">
          <div className="steps__wrapper">
            <ol className="steps__list">
              {steps.map(({ title, number, description }, index) => {
                return (
                  <li
                    className="steps__list__item"
                    key={title}
                    data-aos="fade-up"
                    data-aos-offset="20"
                    data-aos-delay={(index + 2) * 50}
                  >
                    <div className="steps__list__holder">
                      <span className="steps__list__number" aria-hidden="true">
                        {number}
                      </span>
                    </div>
                    <div className="steps__list__holder">
                      <Text
                        as="h3"
                        size="md"
                        aria-label={`${number}, ${title}`}
                      >
                        {title}
                      </Text>
                      <Text size="md">{description}</Text>
                    </div>
                  </li>
                )
              })}
            </ol>
          </div>
          <div className="steps__button" data-aos="fade-up">
            <Button
              fluid
              data-aos="fade-up"
              data-aos-delay="100"
              onClick={() => {
                goToForm()
                prepareToMakeTrack()
                analytics.track("buttonClickGa", {
                  eventName: "IniciarSimulacaoPassos",
                })
              }}
            >
              iniciar simulação
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Steps
